




















































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { MedicaoReceptor, Receptor } from '@/core/models/residuo';
import { MedicaoReceptorService, ReceptorService } from '@/core/services/residuo';

@Component
export default class ListaMedicaoReceptor extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:"actions" ,sortable: false },
    { text: 'Numero', value: 'numero', type:'number' },
    { text: 'Data', value: 'data' },
    { text: 'Receptor', value: 'contratoReceptor.numeroReceptor' },
  ];
  item = new MedicaoReceptor();
  service = new MedicaoReceptorService();

  receptorService = new ReceptorService();
  receptores: Receptor[] = [];
  isReceptorLoading: boolean = false;
  onSearchReceptor: any = null;

  sheet: boolean = false;
  filtro: any = {
    numero: null,
    receptorId: null,
    dataInicial: '',
    dataFinal: '',
  }
  relatorioDialog: boolean = false;
  routeDetalhar: string = 'detalharMedicaoReceptor';
  idDetalhar: number = 0;
  excelDialog: boolean = false;
  excelOpcao: string = 'padrao';

  @Watch('onSearchReceptor')
  SearchCliente (val: string) {

    if (this.filtro.clienteId) return;
    if (this.isReceptorLoading) return;
    if (!val) return;

    this.isReceptorLoading = true
    this.receptorService.AutoComplete(val).then(
      res => {
        this.receptores = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => (this.isReceptorLoading = false));
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.service.MontaFiltro(this.filtro), "ContratoReceptor.Receptor").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  AbrirDialogCadastro(item: MedicaoReceptor){
    if(item){
      this.service.ObterPorId(item.id, "ContratoReceptor.Receptor, OrdensDestinacao.Residuos, OrdensDestinacao.ContratoReceptor.Receptor").then(
        res => {
          this.item = new MedicaoReceptor(res.data);
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    else{
      this.item = new MedicaoReceptor();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: MedicaoReceptor){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          res => {
            if (res.status == 200){
              return res.data;
            }
          },
          err => {
            if (err.response.status == 403){
              this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
            }
            else{
              this.$swal('Aviso', err.response.data, 'error')
            }
          }
        )
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
    }).then(
      (result) => {
        if(result.value) {
          this.$swal("Aviso", result.value, "success");
          this.Atualizar();
        }
      }
    )
  }  

  AbrirJanelaDetalhar(id: number, rota?: string) {

    if(id > 0){
      this.idDetalhar = id
    }

    if(rota){
      let routeLink = this.$router.resolve({ name: rota, params: { id: this.idDetalhar.toString() } });
      window.open(routeLink.href, '_blank');
    }
    else{
      this.relatorioDialog = true;
    }    
  }

  AbrirJanelaExcel(){
    this.excelDialog = true;
  }

  GerarExcel(opcao: string) {

    if(opcao === "padrao") {
      this.ExportarExcel('tabela');
    }
    else {
      this.service.ExcelBruto(0, -1, [], [], null, [], this.filtro).then(
        res => {
          this.JsonToCSV(res.data.items, "Medições", true);
        },
        err=>{
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } 
          else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
    }
    this.excelDialog = false;
  }

  Imprimir(){
    let routeLink = this.$router.resolve({name: "relatorioMedicaoReceptor",
      query:{
        numero: this.filtro.numero,
        receptorId: this.filtro.receptorId,
        dataInicial: this.filtro.dataInicial,
        dataFinal: this.filtro.dataFinal
      }
    });
    window.open(routeLink.href, '_blank');
  }
}
